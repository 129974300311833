import getOnlineTrainings from '../graphql/queries/getOnlineTrainings.graphql';
import getProduct from '../graphql/queries/getProductBySlug.graphql';
import getOpenTrainingsWithDates from '../graphql/queries/getOpenTrainings.graphql';

const trainingTypeToQuery = {
  'szkolenia-online': {
    query: getOnlineTrainings,
    dataName: 'getOnlineTrainings',
    variables: {},
  },
  'e-szkolenia': {
    query: getProduct,
    dataName: 'getProduct',
    variables: {
      id: 'training',
    },
  },
  'szkolenia-otwarte': {
    query: getOpenTrainingsWithDates,
    dataName: 'getOpenTrainingsWithDates',
    variables: {},
  },
};

export default trainingTypeToQuery;

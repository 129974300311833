import React from 'react';
import PropTypes from 'prop-types';
import SingleTrainingPage from '../../components/SingleTrainingPage';
import Layout from '../../components/Layout';

const OnlineTrainingPage = ({ slug }) => {
  return (
    <Layout>
      <SingleTrainingPage slug={slug} trainingType={'szkolenia-online'} />
    </Layout>
  );
};

OnlineTrainingPage.propTypes = {
  slug: PropTypes.string.isRequired,
};

export default OnlineTrainingPage;

import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { navigate } from 'gatsby-link';
import React, { useState } from 'react';
import trainingTypeToQuery from '../utils/trainingTypeSettings';
import Loader from './Loader';
import SingleTrainingContent from './SingleTrainingContent';

SingleTrainingContent.propTypes = {
  selectedTraining: PropTypes.any,
  onClick: PropTypes.func,
  redirecting: PropTypes.bool,
  trainingType: PropTypes.any,
};
export default function SingleTrainingPage({ slug, trainingType }) {
  const { data, loading } = useQuery(trainingTypeToQuery[trainingType].query);
  const [isRedirecting, setRedirecting] = useState(false);
  if (loading && !data) {
    return <Loader />;
  }
  const dataProp = data[trainingTypeToQuery[trainingType].dataName];
  const selectedTraining = dataProp.find((t) => t.slug === slug);
  if (!selectedTraining) {
    navigate(`/${trainingType}`);
    return null;
  }
  const onlineTrainingsPrefix =
    trainingType === 'szkolenia-online' ? `${trainingType}/` : '';
  const ecrkLink = `https://ecrklex.pl/produkty/${onlineTrainingsPrefix}${selectedTraining.slug}`;
  const buttonText = isRedirecting
    ? 'Za chwilę nastąpi przekierowanie na stronę ECRK Lex ...'
    : 'Kup produkt';
  return (
    <SingleTrainingContent
      imageSrc={selectedTraining.imageSrc}
      title={selectedTraining.title}
      text={selectedTraining.text}
      buttonText={buttonText}
      link={ecrkLink}
      setRedirecting={setRedirecting}
      redirecting={isRedirecting}
    />
  );
}

SingleTrainingPage.propTypes = {
  slug: PropTypes.string.isRequired,
  trainingType: PropTypes.string.isRequired,
};
